import type { IPSearchParameters } from '~/types/provider/search-parameters'

export const disableButtonState: Ref<boolean> = ref(false)

export const useSearchNavigateTo = () => {
  const enableSearchButton = () => {
    disableButtonState.value = false
  }
  const navigate = async (sp: IPSearchParameters, routeBaseName?: string) => {
    if (disableButtonState.value) return

    disableButtonState.value = true
    const searchLocation = sp.searchParameters.location?.fullName

    if (searchLocation == null) {
      await localeNavigateTo({ name: 'search' })
      return
    }

    const query = {
      SearchAddress: searchLocation,
      pageSource: routeBaseName ?? null
    }

    await localeNavigateTo({
      name: 'search',
      query
    })
  }

  return {
    navigate,
    disableButtonState,
    enableSearchButton
  }
}
