<i18n lang="yaml">
en:
  location: Location
  placeholder: Where?

fr:
  location: Endroit
  placeholder: Où?
</i18n>

<template>
  <CommonInputAutocomplete
    ref="buttonEl"
    :text="placeText"
    :disabled="loading"
    :label="t('location')"
    :placeholder="t('placeholder')"
    focus
    @input="(q: string) => getPlaces(q)"
  >
    <template #append-input>
      <CommonClear @clicked="placeText = ''" v-if="placeText" />
    </template>
    <div v-if="places.length > 0" ref="floatingEl" class="floating" :style="floatingStyles">
      <button
        v-for="place in places"
        :key="place.place_id"
        class="list-group-item list-group-item-action"
        @click="() => selectPlace(place)"
      >
        {{ place.structured_formatting.main_text }}
        <small>{{ place.structured_formatting.secondary_text }}</small>
      </button>
    </div>
  </CommonInputAutocomplete>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { useFloating, offset } from '@floating-ui/vue'

import { parseGooglePlace, loadAutocompleteService, getGeocode, searchPlaces } from '~/lib/google-maps/google-place'
import { AppProviderKeys } from '~/providers'
import type { IGooglePlace } from '~/types/common/google-place'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters

const { t } = useI18n({ useScope: 'local' })

const places = ref<google.maps.places.AutocompletePrediction[]>([])
const selectedPlace = ref<IGooglePlace | undefined | null>(sp.searchParameters.location)
const placeText = ref<string>(selectedPlace.value?.fullName ?? '')

const loading = ref<boolean>(true)

const buttonEl = ref<HTMLInputElement | null>(null)
const floatingEl = ref<HTMLDivElement | null>(null)

const { floatingStyles } = useFloating(buttonEl, floatingEl, {
  placement: 'bottom-start',
  middleware: [offset(8)]
})

onClickOutside(buttonEl, () => {
  emit('click:outisde')
})

const loadService = () => {
  loadAutocompleteService().then(() => {
    loading.value = false
  })
}

const selectPlace = async (place: google.maps.places.AutocompletePrediction) => {
  places.value = []
  const geocodingResponse = await getGeocode(place.place_id)
  if (geocodingResponse) sp.updateLocation(parseGooglePlace({ place, geocode: geocodingResponse }))

  emit('changed')
}

const getPlaces = async (input: string): Promise<void> => {
  selectedPlace.value = null
  if (!input) {
    places.value = []
    return
  }

  try {
    places.value = await searchPlaces(input)
  } catch {
    places.value = []
  }
}

onBeforeMount(() => {
  loadService()
})

watch(
  () => sp.searchParameters.location,
  (place: IGooglePlace | undefined) => {
    if (place == null) {
      selectedPlace.value = undefined
      placeText.value = ''
    } else {
      selectedPlace.value = place
      placeText.value = place.fullName
    }
  },
  { deep: true }
)

const emit = defineEmits<{
  changed: [value: void]
  'click:outisde': [value: void]
}>()
</script>

<style lang="scss" scoped>
.floating {
  padding: 1rem 0;
  width: 100%;
}

.list-group-item {
  color: $primary-500;
  position: relative;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 0;
  display: flex;
  flex-direction: column;

  small {
    color: $primary-400;
  }

  &.active,
  &:hover {
    background-color: #f8f9fa;
  }

  &:first-child {
    padding-top: 0.75rem;
  }

  &:last-child {
    padding-bottom: 0.75rem;
  }

  &.list-group-item-action {
    width: 100%;
    font-size: 1rem;
    text-align: inherit;
    &.active {
      background-color: $primary-500;
    }
  }
}
</style>
