<i18n lang="yaml">
en:
  where: Where?

fr:
  where: Où?
</i18n>

<template>
  <span v-bind="$attrs" class="typography-caption text-primary" data-testid="location-name">{{
    location?.fullName ?? t('where')
  }}</span>
</template>

<script lang="ts" setup>
import { AppProviderKeys } from '~/providers'
import type { IGooglePlace } from '~/types/common/google-place'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const { t } = useI18n({ useScope: 'local' })

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters

const props = defineProps<{
  location?: IGooglePlace
}>()

const location = computed(() => props.location ?? sp.searchParameters.location)
</script>
