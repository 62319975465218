<template>
  <button type="button" class="clear" @click="emit('clicked')">
    <IconCircleCloseWhite />
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  clicked: [value: void]
}>()
</script>

<style lang="scss" scoped>
.clear {
  background: none;
  border: 0;
  padding: 0;

  svg {
    display: block;
  }
}
</style>
