<i18n lang="yaml">
en:
  location: Location

fr:
  location: Endroit
</i18n>

<template>
  <div class="flex">
    <button
      v-if="!showOptions"
      type="button"
      ref="buttonEl"
      class="toggle-btn flex-1 bg-transparent border-0 cursor-pointer text-left text-xs text-primary-400 relative w-0"
      @click="toggleOptions"
    >
      <span class="text-primary-350 block text-xs"> {{ t('location') }} </span>
      <SearchLocationText class="block text-sm text-primary font-normal truncate" />
    </button>
    <SearchLocationField v-else @changed="() => locationSelected()" @click:outisde="showOptions = false" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n({ useScope: 'local' })

const showOptions = ref(false)

const toggleOptions = () => (showOptions.value = !showOptions.value)

const locationSelected = () => {
  showOptions.value = false
  emit('set')
}

const emit = defineEmits<{
  set: any
}>()
</script>

<style lang="scss" scoped>
.from-homepage {
  .toggle-btn {
    padding: 0 1.75rem;
  }
}

.from-header {
  .toggle-btn {
    margin: 0.5rem 1rem 0;
  }
}

.toogle-btn {
  &.location-toggle {
    padding-right: 2rem;
    width: 210px;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:not(:first-child)::before {
    position: absolute;
    content: '';
    width: 1px;
    height: calc(100% - 1rem);
    background-color: $primary-100;
    left: 0;
  }
}
:deep(.floating) {
  width: 20rem;
}

:deep(.clear) {
  right: 0.5rem;
  top: -0.25rem;
}
</style>
