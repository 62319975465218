<template>
  <div class="autocomplete w-full" ref="containerEl">
    <label v-if="label" :for="id" class="text-xs text-primary-350 block">{{ label }}</label>
    <div class="flex">
      <input
        v-if="disabled"
        type="text"
        v-bind="$attrs"
        :id="id"
        :value="currentText"
        :class="[inputClasses]"
        disabled
        readonly
        :placeholder="placeholder"
      />
      <input
        v-else
        ref="inputEl"
        type="text"
        v-bind="$attrs"
        :id="id"
        :value="currentText"
        :class="[inputClasses]"
        autocomplete="off"
        :placeholder="placeholder"
        @input="() => inputChanged()"
        @keydown="(e) => keyDown(e)"
        @focus="() => emit('focus')"
        @touchstart="() => emit('focus')"
      />
      <slot name="append-input" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'

const props = defineProps<{
  label?: string
  text?: string
  disabled?: boolean
  placeholder?: string
  focus?: boolean
}>()

const id = `input-${uuidv4()}`

const inputClasses =
  'autocomplete-input w-full focus:ring-0 outline-0 overflow-ellipsis text-primary placeholder:text-primary typography-caption font-normal bg-clip-padding block bg-inherit border-0 p-0'

const emit = defineEmits<{
  change: [value: string]
  input: [value: string]
  focus: [value: void]
  blur: [value: void]
}>()

const currentText = ref<string>(props.text ?? '')
const textSinceLastChange = ref<string>(props.text ?? '')

const inputEl = ref<HTMLInputElement | null>(null)
const containerEl = ref<HTMLDivElement | null>(null)

onClickOutside(containerEl, () => {
  valueChanged()
})

onMounted(() => {
  if (props.focus) {
    setTimeout(() => {
      focus()
    })
  }
})

const inputChanged = () => {
  currentText.value = inputEl.value?.value ?? ''
  emit('input', currentText.value)
}

const keyDown = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'Escape':
      blur()
      currentText.value = textSinceLastChange.value
      break

    case 'Enter':
      blur()
      valueChanged()
      break
  }
}

const valueChanged = () => {
  textSinceLastChange.value = currentText.value
  emit('change', currentText.value)
}

const blur = () => {
  inputEl.value?.blur()
}

watch(
  () => props.text,
  (text) => {
    currentText.value = text as string
  }
)

watch(
  () => props.disabled,
  (disabled) => {
    if (!disabled) nextTick(() => inputEl.value?.focus())
  }
)
</script>

<style scoped lang="scss">
.from-homepage {
  .autocomplete {
    width: 100%;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-top: 0.9rem;
  }
}

.from-header {
  .autocomplete {
    margin: 0.5rem 1rem 0;
  }
}
</style>
