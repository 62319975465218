<template>
  <div v-bind="$attrs">
    <SearchLocationDesktopToggle class="location-toggle" @set="locationSet">
      <CommonInputButton
        class="search-button w-28 m-3 flex-none"
        size="sm"
        variant="highlight"
        v-if="buttonText"
        @click="searchClicked"
        :disabled="disableButtonState"
        >{{ buttonText }}</CommonInputButton
      ></SearchLocationDesktopToggle
    >
  </div>
</template>

<script lang="ts" setup>
import { AppProviderKeys } from '~/providers'
import type { IPSearchParameters } from '~/types/provider/search-parameters'
const { navigate, disableButtonState, enableSearchButton } = useSearchNavigateTo()

const props = defineProps<{
  buttonText?: string
}>()

const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters

onMounted(() => {
  enableSearchButton()
})

const locationSet = async () => {
  if (props.buttonText == null) await navigate(sp)
}

const searchClicked = async () => {
  await navigate(sp)
}
</script>
